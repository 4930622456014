import Layout from "../../components/layout"
import Heading from "../../components/heading"
import HorizontalImage from "../../components/horizontal-image"
import Text from "../../components/text"
import VerticalImage from "../../components/vertical-image"
import VerticalText from "../../components/vertical-text"
import React from 'react';
import { graphql } from "gatsby"

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "recepce8/recepce8-1.jpg" }) { ...fluidImage }
    photo2: file(relativePath: { eq: "recepce8/recepce8-2.jpg" }) { ...fluidImage }
    photo3: file(relativePath: { eq: "recepce8/recepce8-3.jpg" }) { ...fluidImage }
    photo4: file(relativePath: { eq: "recepce8/recepce8-4.jpg" }) { ...fluidImage }
    photo5: file(relativePath: { eq: "recepce8/recepce8-5.jpg" }) { ...fluidImage }
  }
`

const Recepce8Page = ({ data }) => (
  <Layout title="Recepce 8">
    <HorizontalImage marginTop borderBottom image={data.photo1.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo4.childImageSharp.fluid} />
    <VerticalText>
      <Heading>Recepce Drtinova 8, Praha 5, 2016</Heading>
      <Text>
        Jedná se druhou recepci na ulici Drtinova pro areál společnosti libertas. Cílem bylo upravit stávající recepci (která byla velmi členitá a nepřehledná) tak, aby se vzhledově přiblížila recepci Drtinová 10. Mou snahou bylo opět prostor vyčistit a zjednodušit, použít sjednocující prvky jako jsou černá tabule, pultu doplněný literami, atypická světla a podobná barevnost. Členitá stěna s ateliérovým prosklením za recepčním pultem byla zarovnána pomocí laminátové předstěny. V ní vznikly police pro poštu, uzamykatelné skříně a specifické otvory prosvětlující chodbu. Recepční pult z černé probarvené MDF desky doplňují černé litery. Nad pultem je zavěšena ocelová konstrukce do které jsou zasunuty svítící plexisklové kvádry a dřevěné boxy. Prostor recepce byl oživen dřevěnou sochou od Jakuba Flejšara, kterou doplňují dřevěné stolky ze stejného materiálu.
      </Text>
    </VerticalText>
    <HorizontalImage marginTop borderBottom borderTop image={data.photo2.childImageSharp.fluid} />
    <VerticalImage borderRight image={data.photo3.childImageSharp.fluid} />
    <VerticalImage image={data.photo5.childImageSharp.fluid} />
  </Layout>
)

export default Recepce8Page
